import { SOLAR_PANEL_KWH_PRODUCTION, SOLAR_PANEL_YEARLY_PROFIT } from 'features/energy-gardens/landingpage/constants'
import ProductDescriptionList from 'features/energy-gardens/order/components/product-description-list/ProductDescription'
import { HardcodedSolarpanelDescriptionProps } from 'features/energy-gardens/common/hardcoded-solarpanel-description/types'
import { profit } from 'features/energy-gardens/landingpage/components/investment-table/constants'
import { INVESTMENT_SCENARIOS } from 'features/energy-gardens/landingpage/components/investment-table/types'

const HardcodedSolarPanelDescription = ({ quantity }: HardcodedSolarpanelDescriptionProps) => {
  // Constants
  const energyProduction = quantity * SOLAR_PANEL_KWH_PRODUCTION
  const yearlyProfit = profit[INVESTMENT_SCENARIOS.DEFAULT].no_discount[quantity]

  return (
    <ProductDescriptionList
      listItems={[
        `Jaarlijks gemiddeld <strong>${energyProduction} kWh</strong> aan zonne-energie`,
        `Jaarlijks een geschatte opbrengst van <strong>${yearlyProfit} euro</strong>`,
        'Investering voor een periode van <strong>25 jaar</strong>',
        'Jaarlijks <strong>75 euro korting</strong> bij Bolt'
      ]}
    />
  )
}

export default HardcodedSolarPanelDescription
