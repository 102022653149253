export enum INVESTMENT_SCENARIOS {
  DEFAULT = 'default',
  MARKET_PLUS = 'market_plus',
  MARKET_MINUS = 'market_minus',
  ENERGY_CRISIS = 'energy_crisis'
}

export type InvestmentTableProps = {
  scenario: INVESTMENT_SCENARIOS
}

export type ProfitAndRoiTableType = {
  [key in INVESTMENT_SCENARIOS]: {
    discount: {
      [key in number]: string
    }
    no_discount: {
      [key in number]: string
    }
  }
}

export enum COLUMN_KEYS {
  PROFIT_DISCOUNT = 'withDiscountOpbrengst',
  ROI_DISCOUNT = 'withDiscountTerugverdienTijd',
  PROFIT_NO_DISCOUNT = 'withoutDiscountOpbrengst',
  ROI_NO_DISCOUNT = 'withoutDiscountTerugverdienTijd'
}
