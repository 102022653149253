import styles from './ProductDescriptionList.module.scss'
import Icon from 'components/icon/Icon'
import parse from 'html-react-parser'

const ProductDescriptionList = ({ listItems = [] }: { listItems: string[] }) => {
  return (
    <ul className={styles.info}>
      {listItems.map((item, index) => (
        <li key={index}>
          <Icon name="check" />
          <span>{parse(item)}</span>
        </li>
      ))}
    </ul>
  )
}

export default ProductDescriptionList
