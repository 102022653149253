import {
  COLUMN_KEYS,
  INVESTMENT_SCENARIOS,
  ProfitAndRoiTableType
} from 'features/energy-gardens/landingpage/components/investment-table/types'
import { SOLAR_PANEL_AMOUNTS } from 'features/energy-gardens/constants'

export const columnKeys = [
  [COLUMN_KEYS.PROFIT_DISCOUNT],
  [COLUMN_KEYS.ROI_DISCOUNT],
  [COLUMN_KEYS.PROFIT_NO_DISCOUNT],
  [COLUMN_KEYS.ROI_NO_DISCOUNT]
]

export const profit: ProfitAndRoiTableType = {
  [INVESTMENT_SCENARIOS.DEFAULT]: {
    discount: {
      4: '€ 157',
      8: '€ 239',
      12: '€ 322',
      16: '€ 404'
    },
    no_discount: {
      4: '€ 82',
      8: '€ 164',
      12: '€ 247',
      16: '€ 329'
    }
  },
  [INVESTMENT_SCENARIOS.MARKET_PLUS]: {
    discount: {
      4: '€ 170',
      8: '€ 265',
      12: '€ 360',
      16: '€ 455'
    },
    no_discount: {
      4: '€ 95',
      8: '€ 190',
      12: '€ 285',
      16: '€ 380'
    }
  },
  [INVESTMENT_SCENARIOS.MARKET_MINUS]: {
    discount: {
      4: '€ 145',
      8: '€ 215',
      12: '€ 284',
      16: '€ 354'
    },
    no_discount: {
      4: '€ 70',
      8: '€ 140',
      12: '€ 209',
      16: '€ 279'
    }
  },
  [INVESTMENT_SCENARIOS.ENERGY_CRISIS]: {
    discount: {
      4: '€ 414',
      8: '€ 753',
      12: '€ 1092',
      16: '€ 1430'
    },
    no_discount: {
      4: '€ 339',
      8: '€678',
      12: '€1017',
      16: '€1355'
    }
  }
}

const roi: ProfitAndRoiTableType = {
  [INVESTMENT_SCENARIOS.DEFAULT]: {
    discount: {
      4: '9 jaar',
      8: '12 jaar',
      12: '14 jaar',
      16: '14 jaar'
    },
    no_discount: {
      4: '18 jaar',
      8: '18 jaar',
      12: '18 jaar',
      16: '18 jaar'
    }
  },
  [INVESTMENT_SCENARIOS.MARKET_PLUS]: {
    discount: {
      4: '9 jaar',
      8: '11 jaar',
      12: '12 jaar',
      16: '13 jaar'
    },
    no_discount: {
      4: '15 jaar',
      8: '15 jaar',
      12: '15 jaar',
      16: '15 jaar'
    }
  },
  [INVESTMENT_SCENARIOS.MARKET_MINUS]: {
    discount: {
      4: '10 jaar',
      8: '14 jaar',
      12: '15 jaar',
      16: '16 jaar'
    },
    no_discount: {
      4: '21 jaar',
      8: '21 jaar',
      12: '21 jaar',
      16: '21 jaar'
    }
  },
  [INVESTMENT_SCENARIOS.ENERGY_CRISIS]: {
    discount: {
      4: '4 jaar',
      8: '4 jaar',
      12: '4 jaar',
      16: '4 jaar'
    },
    no_discount: {
      4: '4 jaar',
      8: '4 jaar',
      12: '4 jaar',
      16: '4 jaar'
    }
  }
}

export const descriptions = {
  [INVESTMENT_SCENARIOS.DEFAULT]: (
    <p>
      <strong>Uitleg:</strong> In dit scenario gaan we uit van de huidige marktprijs voor elektriciteit en de huidige voorspellingen voor de
      toekomst. Daarmee rekening houdend zal de opbrengst van je panelen gemiddeld zijn, aangezien de marktprijzen voor energie momenteel
      aan de lage kant zijn. De korting bij Bolt zorgt echter voor een mooi rendement en aanzienlijke verlaging van de terugverdientijd.
    </p>
  ),
  [INVESTMENT_SCENARIOS.MARKET_PLUS]: (
    <p>
      <strong>Uitleg:</strong> In het geval dat de marktprijzen voor elektriciteit met 10% zouden stijgen tegenover het huidig scenario,
      betaalt jouw investering zich zichtbaar sneller terug.
    </p>
  ),
  [INVESTMENT_SCENARIOS.MARKET_MINUS]: (
    <p>
      <strong>Uitleg:</strong> In het geval dat de marktprijzen voor elektriciteit met 10% zouden dalen tegenover het huidig scenario,
      betaalt jouw investering zich trager terug. Dankzij de korting bij Bolt blijf je genieten van een mooie investering.
    </p>
  ),
  [INVESTMENT_SCENARIOS.ENERGY_CRISIS]: (
    <p>
      <strong>Uitleg:</strong> In dit scenario gaan we uit van een energiecrisis waarin de marktprijzen voor elektriciteit de lucht in
      schieten. Wij rekenen met een prijs van €250/MWh, maar in 2022 was de prijs op een bepaald moment zelfs €450/MWh. In dit geval zullen
      je panelen zichzelf nog sneller terugbetalen.
    </p>
  )
}

const getMobileRow = (amount: number, scenario: INVESTMENT_SCENARIOS, pricePerUnit: number) => ({
  [COLUMN_KEYS.PROFIT_DISCOUNT]: profit[scenario].discount[amount],
  [COLUMN_KEYS.ROI_DISCOUNT]: roi[scenario].discount[amount],
  [COLUMN_KEYS.PROFIT_NO_DISCOUNT]: profit[scenario].no_discount[amount],
  [COLUMN_KEYS.ROI_NO_DISCOUNT]: roi[scenario].no_discount[amount],
  rowHeaderText: (
    <span>
      <strong>{amount} zonnepanelen</strong> <small>~ Investering: €{pricePerUnit * amount}</small>
    </span>
  )
})

const getDesktopRow = (amount: number, scenario: INVESTMENT_SCENARIOS, pricePerUnit: number) => ({
  [COLUMN_KEYS.PROFIT_DISCOUNT]: profit[scenario].discount[amount],
  [COLUMN_KEYS.ROI_DISCOUNT]: roi[scenario].discount[amount],
  [COLUMN_KEYS.PROFIT_NO_DISCOUNT]: profit[scenario].no_discount[amount],
  [COLUMN_KEYS.ROI_NO_DISCOUNT]: roi[scenario].no_discount[amount],
  rowHeaderText: (
    <div>
      <strong>{amount} zonnepanelen</strong>
      <br />
      <small>Investering: €{pricePerUnit * amount}</small>
    </div>
  )
})

export const getTableProps = (slug: string, scenario: INVESTMENT_SCENARIOS, pricePerUnit: number) => ({
  mobile: {
    columnHeaders: {
      [COLUMN_KEYS.PROFIT_DISCOUNT]: 'Jaarlijkse opbrengst met korting bij Bolt',
      [COLUMN_KEYS.ROI_DISCOUNT]: 'Terugverdientijd met korting bij Bolt',
      [COLUMN_KEYS.PROFIT_NO_DISCOUNT]: 'Jaarlijkse opbrengst zonder korting bij Bolt',
      [COLUMN_KEYS.ROI_NO_DISCOUNT]: 'Terugverdientijd zonder korting bij Bolt'
    },
    columnKeys,
    rowKeys: SOLAR_PANEL_AMOUNTS[slug].map((amount) => [amount]),
    rows: {
      4: getMobileRow(4, scenario, pricePerUnit),
      8: getMobileRow(8, scenario, pricePerUnit),
      12: getMobileRow(12, scenario, pricePerUnit),
      16: getMobileRow(16, scenario, pricePerUnit)
    }
  },
  desktop: {
    columnHeaders: {
      [COLUMN_KEYS.PROFIT_DISCOUNT]: 'Jaarlijkse opbrengst',
      [COLUMN_KEYS.ROI_DISCOUNT]: 'Terugverdientijd',
      [COLUMN_KEYS.PROFIT_NO_DISCOUNT]: 'Jaarlijkse opbrengst',
      [COLUMN_KEYS.ROI_NO_DISCOUNT]: 'Terugverdientijd'
    },
    columnKeys,
    rowKeys: SOLAR_PANEL_AMOUNTS[slug].map((amount) => [amount]),
    rows: {
      4: getDesktopRow(4, scenario, pricePerUnit),
      8: getDesktopRow(8, scenario, pricePerUnit),
      12: getDesktopRow(12, scenario, pricePerUnit),
      16: getDesktopRow(16, scenario, pricePerUnit)
    }
  }
})
