import { FC, useEffect, useRef } from 'react'
import { DrawerProps as Props } from '../types'
import { CSSTransition } from 'react-transition-group'
import styles from './MobileDrawer.module.scss'
import Icon from 'components/icon/Icon'
import classNames from 'classnames'
import { CldImage } from 'next-cloudinary'

const MobileDrawer: FC<Props> = ({ children, extraHeaderElement, isOpen, onRequestClose, contentClassName, headerImage, title }) => {
  // Refs
  const drawerRef = useRef<HTMLDivElement>(null)

  /**
   * Hides modal on click outside and on ESC
   */
  useEffect(() => {
    const handleClickOutside = (e: Event) => {
      if (drawerRef.current && !drawerRef.current.contains(e.target as HTMLDivElement)) {
        onRequestClose()
      }
    }
    const closeOnEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onRequestClose()
      }
    }

    document.addEventListener('click', handleClickOutside, true)
    document.addEventListener('keydown', closeOnEscape)

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
      document.removeEventListener('keydown', closeOnEscape)
    }
  }, [onRequestClose])

  /**
   * Stop scroll on body if modal is open
   */
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll')
    }

    return () => document.body.classList.remove('no-scroll')
  }, [isOpen])

  return (
    <>
      <CSSTransition nodeRef={drawerRef} in={isOpen} timeout={300} classNames="slide-up" unmountOnExit>
        <div className={styles.drawer} ref={drawerRef}>
          <div
            className={classNames(styles['drawer-header'], {
              [styles['with-extra']]: !!extraHeaderElement,
              [styles['with-image']]: !!headerImage
            })}
          >
            {extraHeaderElement}

            <button className={styles['drawer-close']} onClick={onRequestClose}>
              <Icon name="x" size={14} />
            </button>

            {headerImage && <CldImage className={styles['header-img']} src={headerImage.url} alt={headerImage.alt} fill />}
          </div>

          <div className={classNames(styles['drawer-content'], contentClassName)}>
            {title && <h2 className={styles.title}>{title}</h2>}
            {children}
          </div>
        </div>
      </CSSTransition>
      {isOpen && <div className="overlay" />}
    </>
  )
}

export default MobileDrawer
